var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right"
  }, [_vm._m(0), _vm.myAuditInfo.auditStatusName ? _c('ul', {
    staticClass: "auditList"
  }, [_c('li', [_c('img', {
    attrs: {
      "src": require("../center/images/user.png"),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.myAuditInfo.account) + " ")]), _c('li', [_vm._v(_vm._s(_vm.myAuditInfo.auditStatusName))]), _c('li', [_vm._v(_vm._s(_vm.myAuditInfo.submitTime))]), _c('li', {
    on: {
      "click": function click($event) {
        return _vm.goDetail();
      }
    }
  }, [_c('p', [_vm._v("查看详情")])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right-title"
  }, [_c('span', [_vm._v("我的审核")])]);
}];
export { render, staticRenderFns };