import "core-js/modules/es.array.push.js";
export default {
  data: function data() {
    return {
      accountCode: "",
      loginCompanyName: "",
      myAuditInfo: {}
    };
  },
  created: function created() {
    this.getMyAudit();
  },
  methods: {
    goDetail: function goDetail() {
      this.$router.push({
        path: "/user-center/audit-detail",
        query: {
          submitTime: this.myAuditInfo.submitTime,
          personName: this.myAuditInfo.personName,
          submitType: this.myAuditInfo.submitType
        }
      });
    },
    getMyAudit: function getMyAudit() {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/customer/myAuditStatus";
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == "200") {
          _this.myAuditInfo = res.data.data;
        }
      });
    }
  }
};